<template>
    <div></div>
</template>

<script>
    export default {
        name: 'Details',
        data() {
            return {
                //
            };
        },
        methods: {
            //
        },
    };
</script>

<style lang="scss" scoped></style>
